import HubspotForm from "react-hubspot-form"
import React from "react"
import dannyImg from "../images/photo1.png"
import jamesImg from "../images/photo4.png"
import stephenImg from "../images/photo3.png"
import karriemImg from "../images/photo2.png"
import brielleImg from "../images/photo5.png"

import Layout from "../components/layout"
import Banner from "../components/banner"

const VideoFormPage = () => {
  const cardData = [
    {
        key: "danny",
        persenterImg: dannyImg,
        presenterName: "Congressman Danny K. Davis,",
        presenterTitle: "Representing the 7th District of Illinois"
    },
    {
        key: "james",
        persenterImg: jamesImg,
        presenterName: "RADM (ret) James M. Galloway MD, FACP, FACC,",
        presenterTitle: "Former Assistant U.S. Surgeon General"
    },
    {
        key: "stephen",
        persenterImg: stephenImg,
        presenterName: "Stephen Brown, MSW, LCSW,",
        presenterTitle: "Director of Preventative Emergency Medicine, UI Hospital and Health Sciences System"
    },
    {
        key: "karriem",
        persenterImg: karriemImg,
        presenterName: "Karriem Watson DHSc, MS, MPH,",
        presenterTitle: "Associate Executive Director, UI Health Mile Square Health Center"
    },
    {
        key: "brielle",
        persenterImg: brielleImg,
        presenterName: "Brielle Osting, AM, MPP",
        presenterTitle: "Senior Community Engagement Manager"
    },
  ]

  const cards = cardData.map(request => {
    const { key, persenterImg, presenterName, presenterTitle } = request

    return (
      <div key={key}>
        <div className="presenter-card">
          <img src={persenterImg} alt="Presenter" />
          <div className="presenter-caption">
            <h4 className="presenter-name">{presenterName}</h4>
            <p className="body-text presenter-title">{presenterTitle}</p>
          </div>
        </div>
        <hr />
      </div>
    )
  })

  return (
    <Layout>
      <section className="video-form">
        <Banner
          mediaType="Video"
          mediaDuration="Oct 14, 2020  |  52 mins"
          mediaText="Recap: Urgent Means Project Virtual Town Hall"
          isFormPage={true}
        />
        <div className="video-contents">
          <div className="video-description">
            <p className="paragraph-text">CareAdvisors recently collaborated with Congressman Danny K. Davis representing the 7th District of Illinois and an esteemed panel of Chicago's leading public health advocates to discuss patient access issues in Chicagoland. Terry Mason, MD, of WVON 1690 AM Radio, served as moderator.</p>
            <div className="presenters">
                <p className="paragraph-text">Presenters</p>
                <hr/>
                <div className="presenters-cards">
                  {cards}
                </div>
            </div>
          </div>
          <HubspotForm
            className="video-hs-form"
            portalId="4891903"
            formId="f846cc44-5363-4523-8ba2-9b35e747e6ea"
          />
        </div>
      </section>
    </Layout>
  )
}

export default VideoFormPage
